<template>
  <b-card title="Posts">
    <b-tabs>
      <b-tab @click="$router.push('/add-article')" active>
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Add Post / News</span>
        </template>
      </b-tab>
      <b-tab @click="$router.push('/all-articales')">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>All Posts</span>
        </template>
      </b-tab>
      <b-tab @click="$router.push('/all-news')">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>All News</span>
        </template>
      </b-tab>
    </b-tabs>

    <div v-if="$route.path === '/add-article'">
      <AddArticale />
    </div>
    <div v-else>
      <router-view />
    </div>
  </b-card>
</template>

<script>
import {
  BTabs, BTab, BCard,
} from 'bootstrap-vue'

import AddArticale from './AddArticale.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    AddArticale,
  },
}
</script>
